<template>
  <div class="grid">
    <div class="col-12">
      <Card>
        <template #title> Dados Funcionais (Diretas)</template>
        <template #content>
          <div v-if="matriculasList.length > 1" class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <Dropdown
                v-model="matricula"
                :options="matriculasList"
                data-key="id"
                placeholder="Matrícula"
                style="width: 100%">
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                    <span>
                      {{ slotProps.value.matricula }} -
                      {{ slotProps.value.entidadeNome }}
                    </span>
                  </div>
                </template>
                <template #option="slotProps">
                  <span class="card-desktop">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome }}
                  </span>
                  <span class="card-mobile">
                    {{ slotProps.option.matricula }} -
                    {{ slotProps.option.entidadeNome | limitadorString }}
                  </span>
                </template>
              </Dropdown>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <div class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.nome"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Nome Completo</label>
              </div>
            </div>
            <div class="field col-6 md:col-2">
              <div class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.matricula"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Matricula</label>
              </div>
            </div>
            <div class="field col-12 md:col-4">
              <div class="p-float-label">
                <InputText
                  id="previdencia"
                  v-model="matricula.previdencia"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="previdencia">Previdência</label>
              </div>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-3">
              <div class="p-float-label">
                <InputText
                  id="regime"
                  v-model="matricula.regime"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="regime">Regime</label>
              </div>
            </div>
            <div class="field col-6 md:col-3">
              <div class="p-float-label">
                <InputText
                  id="nivelAtividade"
                  v-model="matricula.nivelAtividade"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nivelAtividade">Nível de Atividade</label>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <div class="p-float-label">
                <InputText
                  id="cargo"
                  v-model="matricula.cargo"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="cargo">Cargo</label>
              </div>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <div class="p-float-label">
                <InputText
                  id="classificacaoFuncional"
                  v-model="matricula.classificacaoFuncional"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="classificacaoFuncional"
                  >Classificação Funcional</label
                >
              </div>
            </div>
            <div class="field col-6 md:col-3">
              <div class="p-float-label">
                <InputText
                  id="quadro"
                  v-model="matricula.quadro"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="quadro">Quadro</label>
              </div>
            </div>
            <div class="field col-6 md:col-3">
              <div class="p-float-label">
                <InputText
                  id="grupoOcupacional"
                  v-model="matricula.grupoOcupacional"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="grupoOcupacional">Grupo Ocupacional</label>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <div class="p-float-label">
                <InputText
                  id="lotacao"
                  v-model="matricula.lotacao"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="lotacao">Lotação</label>
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <div class="p-float-label">
                <InputText
                  id="formaAdmissao"
                  v-model="matricula.formaAdmissao"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="formaAdmissao">Forma de Admissão</label>
              </div>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-3">
              <div class="p-float-label">
                <InputText
                  id="pasep"
                  v-model="matricula.pasep"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="pasep">PASEP</label>
              </div>
            </div>
            <div class="field col-6 md:col-2">
              <div class="p-float-label">
                <InputText
                  id="dataAdmissao"
                  type="text"
                  :value="matricula.dataAdmissao | formatarData"
                  class="input-text"
                  disabled />
                <label for="dataAdmissao">Data de Admissão</label>
              </div>
            </div>
            <div class="field col-6 md:col-2">
              <div class="p-float-label">
                <InputText
                  id="dataExoneracao"
                  type="text"
                  :value="matricula.dataExoneracao | formatarData"
                  class="input-text"
                  disabled />
                <label for="dataExoneracao">Data de Exoneração</label>
              </div>
            </div>
            <div class="field col-6 md:col-2">
              <div class="p-float-label">
                <InputText
                  id="dataExercicio"
                  type="text"
                  :value="matricula.dataExercicio | formatarData"
                  class="input-text"
                  disabled />
                <label for="dataExercicio">Data do Exercício</label>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Dados Profissionais</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.funcaoGratificada"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Cargo Comissi./Função Grat.</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.exerceMesmo"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Exerce o(a) Mesmo(a)</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.simbolo"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Símbolo</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-3">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.tempoServicoEstado"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Tempo de Serviço</label>
              </span>
            </div>
            <div class="field col-6 md:col-3">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.tempoServicoExterno"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Tempo de Serviço Externo</label>
              </span>
            </div>
            <div class="field col-6 md:col-3">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.tempoServicoAposentadoria"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Tempo de Serviço Aposentadoria</label>
              </span>
            </div>
            <div class="field col-6 md:col-3">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.prazo"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Prazo</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.secretaria"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Secretaria</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.situacao"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Situação</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.localTrabalho"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Local de Trabalho</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.cidade"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Cidade</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.unidadePagadora"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Unidade Pagadora</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.escala"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Escala</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.inicioPrimeiroTurno"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Primeiro Turno</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.inicioSegundoTurno"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Segundo Turno</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-3">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.motivoSaida"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Motivo da Saída</label>
              </span>
            </div>
            <div class="field col-6 md:col-3">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.afastamento"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Afastamento</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.dataAfastamento | formatarData"
                  class="input-text"
                  disabled />
                <label for="nome">Data do Afastamento</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Aposentadoria</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.tipoAposentadoria"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Tipo de Aposentadoria</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.dataAposentadoria | formatarData"
                  class="input-text"
                  disabled />
                <label for="nome">Data da Aposentadoria</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Informações dos Dependentes</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.dependenteIr"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Dependente IR</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.dependenteSf"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Dependente SF</label>
              </span>
            </div>
            <div class="field col-12 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.dependenteEx"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Dependente EX</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12">
      <Card>
        <template #title>Outros</template>
        <template #content>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.instrucao"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Instrução</label>
              </span>
            </div>
            <div class="field col-6 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.exCombatente"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Ex-Combatente</label>
              </span>
            </div>
            <div class="field col-6 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  v-model="matricula.deficiente"
                  type="text"
                  class="input-text"
                  disabled />
                <label for="nome">Deficiente</label>
              </span>
            </div>
          </div>
          <div class="p-fluid grid mb-1">
            <div class="field col-6 md:col-4">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.sindicato"
                  class="input-text"
                  disabled />
                <label for="nome">Sindicato</label>
              </span>
            </div>
            <div class="field col-6 md:col-2">
              <span class="p-float-label">
                <InputText
                  id="nome"
                  type="text"
                  :value="matricula.optante"
                  class="input-text"
                  disabled />
                <label for="nome">Optante</label>
              </span>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import MatriculaService from '@/service/MatriculaService.js'
import PortalServidorService from '@/service/PortalServidorService'
import { substituirValores } from '@/utils/SelecionarObjetoPadrao'

export default {
  data() {
    return {
      matriculasList: [],
      matricula: {},
      loading: false,
    }
  },

  created() {
    this.matriculaService = new MatriculaService(this.$http)
    this.portalServidorService = new PortalServidorService(this.$http)
  },

  mounted() {
    this.loading = true
    this.carregarDados()
  },

  methods: {
    carregarDados() {
      this.loading = true
      this.matriculaService
        .getMatriculasUsuarioLogado()
        .then((res) => {
          this.getDadosFuncionaisByCpf(res[0].cpf)
          this.limpar()
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
        })
    },
    getDadosFuncionaisByCpf(matricula) {
      this.portalServidorService
        .getDadosFuncionaisByCpf(matricula)
        .then((res) => {
          this.matriculasList = res
          for (const idx in this.matriculasList) {
            this.matriculasList[idx].id = parseInt(idx)
            this.matriculasList[idx] = substituirValores(
              this.matriculasList[idx],
            )
          }
          this.matricula = this.matriculasList[0]
        })
        .catch((err) => {
          const mensagem = err.response
            ? err.response.data.message
            : 'Erro de conexão com a aplicação.'
          this.limpar()
          this.$toast.add({
            severity: 'error',
            summary: mensagem,
            life: 10000,
          })
        })
    },
    limpar() {
      this.loading = false
    },
  },
}
</script>

<style>
.input-text {
  width: 100%;
  color: black;
}
</style>
